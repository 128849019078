import { useAuth0 } from "@auth0/auth0-react";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { socket } from "../../socket";
import axios from "axios";
import {
  SpeechTranslationConfig,
  AudioConfig,
  TranslationRecognizer,
  ResultReason,
  CancellationReason,
} from "microsoft-cognitiveservices-speech-sdk";
import { DeviceList } from "./DeviceList";
import {
  addBroadcasterToDB,
  checkUserServiceUsage,
  getSpeechServiceToken,
  getUserSubscription,
  startStream,
  stopStream,
  updateElapsedTime,
} from "../../api/BroadcastApi";
const BroadcastPage = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const navigate = useNavigate();
  const [recognizer, setRecognizer] = useState(null);
  const [streamLive, setStreamLive] = useState(false);
  const [isActiveSub, setIsActiveSub] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [overLimit, setOverLimit] = useState(false);
  const timerRef = useRef(null); // Use ref to keep track of the timer
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    // Listen for the 'user_count' event from the server
    socket.on("user_count", (data) => {
      console.log("count");
      console.log(data);
      setUserCount(data); // Update the user count in the state
    });

    // Handle disconnect event when the component unmounts
    return () => {
      socket.off("user_count"); // Clean up listener
    };
  }, [socket]);

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setElapsedTime((prev) => prev + 1);
    }, 5000 * 60); // Increment elapsed time every second
  };

  const stopTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = null;
  };

  useEffect(() => {
    const updateTime = async () => {
      const token = await getAccessTokenSilently();
      updateElapsedTime(user.nickname, user.stripe_customer_id, token);
    };
    if (isAuthenticated) {
      updateTime();
    }
  }, [elapsedTime]);

  const startRecognition = async () => {
    if (recognizer) {
      const token = await getAccessTokenSilently();
      startStream(user.nickname, token);
      recognizer.startContinuousRecognitionAsync(
        () => {
          console.log("Translation started.");
          setStreamLive(true);
          startTimer();
        },
        (err) => {
          console.error("Error starting translation:", err);
        }
      );
    }
  };

  const stopRecognition = async () => {
    if (recognizer) {
      const token = await getAccessTokenSilently();
      stopStream(user.nickname, token);

      recognizer.stopContinuousRecognitionAsync(
        () => {
          setStreamLive(false);
          console.log("Translation stopped.");
          stopTimer();
        },
        (err) => {
          console.error("Error stopping translation:", err);
        }
      );
    }
  };

  const getUserSub = async () => {
    const token = await getAccessTokenSilently();

    const response = await getUserSubscription(user.stripe_customer_id, token);

    console.log(response.data.data);
    if (response.data.data !== "active" && response.data.data !== "trialing") {
      navigate("/pricing");
    } else {
      setIsActiveSub(true);
    }
  };

  const addUser = async () => {
    const token = await getAccessTokenSilently();
    addBroadcasterToDB(user.nickname, token);
  };

  const check_limit = async () => {
    const token = await getAccessTokenSilently();
    const response = await checkUserServiceUsage(user.nickname, token);

    if (response.data["result"] == true) {
      setOverLimit(true);
    } else if (response.data["result"] == false) {
      setOverLimit(false);
    }
  };
  useEffect(() => {
    const setupRecognizer = async () => {
      if (!isAuthenticated) return;

      if (isAuthenticated) {
        getUserSub();
        addUser();
        check_limit();

        socket.emit("join_broadcast_room", user.nickname);
      }
      const authToken = await getAccessTokenSilently();
      const speechServiceToken = await getSpeechServiceToken(authToken);
      if (!speechServiceToken) return;
      const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
      const speechConfig = SpeechTranslationConfig.fromAuthorizationToken(
        speechServiceToken.data.access_token,
        speechServiceToken.data.region
      );
      speechConfig.speechRecognitionLanguage = "sv-SE"; // Language to recognize
      speechConfig.addTargetLanguage("en"); // Add target languages as needed
      speechConfig.addTargetLanguage("fr");
      speechConfig.addTargetLanguage("it");

      const recognizerInstance = new TranslationRecognizer(
        speechConfig,
        audioConfig
      );

      // Event handlers
      recognizerInstance.recognizing = (s, e) => {
        console.log(`Recognizing text: ${e.result.text}`);
      };

      recognizerInstance.recognized = (s, e) => {
        if (e.result.reason === ResultReason.TranslatedSpeech) {
          console.log(e.result.translations);
          socket.emit(
            "translationResults",
            e.result.translations,
            user.nickname
          );
        }
      };

      recognizerInstance.canceled = (s, e) => {
        console.error(`Recognition canceled: ${e.reason}`);
        if (e.reason === CancellationReason.Error) {
          console.error(`Error details: ${e.errorDetails}`);
        }
      };

      recognizerInstance.sessionStopped = (s, e) => {
        console.log("Session stopped.");
      };

      setRecognizer(recognizerInstance);
    };

    setupRecognizer();

    // Cleanup: stop and close the recognizer on unmount
    return () => {
      if (recognizer) {
        recognizer.close();
      }
    };
  }, [isAuthenticated]); // Run effect on initial render and when these dependencies change

  useEffect(() => {}, []);
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated &&
    isActiveSub && (
      <>
        <section className="section is-small">
          <article className="message is-dark">
            <div className="message-header">
              <p style={{ fontFamily: "Instrument Sans" }}>Instructions</p>
            </div>
            <div className="message-body">
              <p style={{ fontFamily: "Instrument Sans" }}>
                1. Approve browser to access Input device
                <br />
                2. Choose the input for the audio under Device List
                <br />
                3. Press start translation
                <br />
                4. Once the button is pressed, users can access the viewer page
                and choose the desired language.
                <br />
                <strong style={{ fontFamily: "Instrument Sans" }}>
                  IMPORTANT! Do not close this tab during stream, as it will
                  stop the transcription to users as well
                </strong>
              </p>
            </div>
          </article>
        </section>
        <section className="section is-medium">
          <div className="columns is-multiline is-mobile is-centered">
            <div
              className="column is-full"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <p className="montserrat-text-logo">
                {user.nickname.toUpperCase()}
              </p>
            </div>
            <div
              className="column is-full"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="buttons">
                {!overLimit ? (
                  <>
                    {!streamLive ? (
                      <button
                        className="button is-primary is-light is-focused montserrat-body"
                        onClick={startRecognition}
                      >
                        START RECORDING
                      </button>
                    ) : (
                      <button className="button is-warning is-light is-focused is-disabled montserrat-body">
                        RECORDING...
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    className="button is-primary is-light is-focused"
                    disabled
                  >
                    No hours left
                  </button>
                )}
                <button
                  className="button is-danger is-light is-focused montserrat-body"
                  onClick={stopRecognition}
                >
                  STOP RECORDING
                </button>
              </div>
            </div>
            {streamLive && <p>Number of viewers {userCount}</p>}
            <div
              className="column is-full"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <audio id="audio" autoPlay muted></audio>

              <Link target="_blank" to={`/viewerlanding/${user.nickname}`}>
                <button className="button is-info is-light is-small">
                  {" "}
                  <p className="montserrat-text-logo">JOIN STREAM</p>
                </button>
              </Link>
            </div>
            <DeviceList />
          </div>
        </section>
      </>
    )
  );
};

export default BroadcastPage;
