import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";

export const NavBarButtons = () => {
  const { isAuthenticated } = useAuth0();
  const [isActive, setisActive] = useState(false);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [modalValue, setModalValue] = useState();
  const [unknownValue, setUnknownValue] = useState(false);
  const { loginWithRedirect, logout } = useAuth0();

  const goToBroadcast = async () => {
    try {
      const response = await fetch(`/api/get_broadcast_id/${modalValue}`, {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      console.log(data);
      if (data.result == null) {
        setUnknownValue(true);
      } else {
        setUnknownValue(false);
        window.location.href = `/viewerlanding/${modalValue}`;
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="navbar-brand">
        <a className="navbar-item montserrat-text-logo" href="/">
          CHURCHLINGO
        </a>
        <a
          onClick={() => {
            setisActive(!isActive);
          }}
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          role="button"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBurger"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div
        id="navbarBurger"
        className={`navbar-menu ${isActive ? "is-active" : "is-hidden"}`}
      >
        <div class="navbar-end">
          <a href="/" className="navbar-item">
            <p>HOME</p>
          </a>
          <a href="/about-us" className="navbar-item">
            About
          </a>
          <a href="/pricing" className="navbar-item">
            Pricing
          </a>
          <hr class="navbar-divider" />
          <a href="mailto: support@churchlingo.se" class="navbar-item">
            Report an issue
          </a>
          <hr class="navbar-divider" />
          <div class="navbar-item">
            <div class="buttons">
              <a
                class="button is-link"
                onClick={() => setIsActiveModal(!isActiveModal)}
              >
                <p className="title is-6" style={{ color: "#FFFF" }}>
                  {" "}
                  Join a broadcast
                </p>
              </a>
              {!isAuthenticated ? (
                <>
                  <a
                    class="button is-primary"
                    onClick={() => {
                      loginWithRedirect();
                    }}
                  >
                    <p className="title is-6" style={{ color: "#FFFF" }}>
                      Start a broadcast
                    </p>
                  </a>
                </>
              ) : (
                <a class="button is-warning" onClick={() => logout()}>
                  <p className="title is-6" style={{ color: "#FFFF" }}>
                    Log Out
                  </p>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <a href="/" className="navbar-item">
            <p className="montserrat-header">HOME</p>{" "}
          </a>

          <a href="/about-us" className="navbar-item ">
            <p className="montserrat-header">ABOUT</p>
          </a>
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">
              <p className="montserrat-header">MORE</p>
            </a>

            <div className="navbar-dropdown">
              <a href="/pricing" className="navbar-item">
                <p className="montserrat-header">PRICING</p>
              </a>

              <hr class="navbar-divider" />
              <a href="mailto: support@churchlingo.se" class="navbar-item">
                <p className="montserrat-header-bold">SUPPORT</p>
              </a>
            </div>
          </div>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <a
                class="button is-link"
                onClick={() => setIsActiveModal(!isActiveModal)}
              >
                <p
                  className="montserrat-body"
                  style={{ letterSpacing: "2px", color: "#FFFF" }}
                >
                  Join a broadcast
                </p>
              </a>
              {!isAuthenticated ? (
                <>
                  <a
                    class="button is-primary"
                    onClick={() => loginWithRedirect()}
                  >
                    <p
                      className="montserrat-body"
                      style={{ letterSpacing: "2px", color: "#FFFF" }}
                    >
                      Start a broadcast
                    </p>
                  </a>
                </>
              ) : (
                <>
                  <a class="button is-primary" href="/broadcast">
                    <p
                      className="montserrat-body"
                      style={{ letterSpacing: "2px", color: "#FFFF" }}
                    >
                      Start a broadcast
                    </p>
                  </a>
                  <a class="button is-warning" onClick={() => logout()}>
                    <p
                      className="montserrat-body"
                      style={{ letterSpacing: "2px", color: "#FFFF" }}
                    >
                      {" "}
                      Log out
                    </p>
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        id="modal-js-example"
        class={`modal ${isActiveModal ? "is-active" : ""}`}
      >
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title montserrat-body">ENTER BROADCAST CODE</p>
            <button
              class="delete"
              aria-label="close"
              onClick={() => {
                setIsActiveModal(!isActiveModal);
              }}
            ></button>
          </header>
          <section class="modal-card-body">
            <input
              class="input"
              value={modalValue}
              onChange={(e) => {
                setModalValue(e.target.value);
              }}
            />
            {unknownValue && <p style={{ color: "red" }}>Wrong code</p>}
          </section>
          <footer class="modal-card-foot">
            <div class="buttons">
              <button class="button is-success" onClick={() => goToBroadcast()}>
                Go
              </button>
              <button
                class="button"
                onClick={() => {
                  setIsActiveModal(!isActiveModal);
                }}
              >
                Cancel
              </button>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};
