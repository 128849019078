import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import standing24 from "../../assets/standing-24.png";
import standing5 from "../../assets/standing-5.png";
export default function PricingPage() {
  const { loginWithPopup, isAuthenticated } = useAuth0();

  return (
    <>
      <section class="hero is-info" style={{ backgroundColor: "#577590" }}>
        <div class="hero-body">
          <div className="hero-div">
            <div style={{ display: "flex", width: "100%", zIndex: 1 }}>
              <img src={standing24} alt="" style={{ maxWidth: "15vw" }} />
            </div>
            <p class="montserrat-large-title-absolute">PRICING PLAN</p>
            <div style={{ display: "flex", zIndex: 1 }}>
              <img
                src={standing5}
                alt=""
                style={{
                  maxWidth: "15vw",
                  transform: "scaleX(-1)",
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <div class="pricing-table">
        <div class="pricing-plan is-danger">
          <div class="plan-header montserrat-normal">SMALL</div>
          <div class="plan-price">
            <span class="plan-price-amount">
              <span class="plan-price-currency">kr</span>299
            </span>
            /month
          </div>
          <div class="plan-items">
            <div class="plan-item montserrat-bold">10 Hours per month</div>
            <div class="plan-item montserrat-bold">
              For organisations with up to 100 members
            </div>
          </div>
          <div class="plan-footer">
            {!isAuthenticated ? (
              <button
                class="button is-fullwidth montserrat-bold"
                onClick={() => loginWithPopup()}
              >
                Get Started
              </button>
            ) : (
              <a href="https://buy.stripe.com/14kbLBcdzcmT2tOdQS">
                <button cclass="button is-fullwidth montserrat-bold">
                  {" "}
                  Get Started
                </button>
              </a>
            )}
          </div>
        </div>

        <div class="pricing-plan is-warning">
          <div class="plan-header montserrat-body">MEDIUM</div>
          <div class="plan-price">
            <span class="plan-price-amount">
              <span class="plan-price-currency">kr</span>499
            </span>
            /month
          </div>
          <div class="plan-items">
            <div class="plan-item montserrat-bold">20 Hours per month</div>
            <div class="plan-item montserrat-bold">
              For organisations with up to 500 members
            </div>
          </div>
          <div class="plan-footer">
            {!isAuthenticated ? (
              <button
                class="button is-fullwidth montserrat-bold"
                onClick={() => loginWithPopup()}
              >
                Get Started
              </button>
            ) : (
              <a href="https://buy.stripe.com/bIY7vlb9vdqXfgA6or">
                <button class="button is-fullwidth montserrat-bold">
                  {" "}
                  Get Started
                </button>
              </a>
            )}
          </div>
        </div>

        <div class="pricing-plan is-primary">
          <div class="plan-header montserrat-body">LARGE</div>
          <div class="plan-price">
            <span class="plan-price-amount">
              <span class="plan-price-currency">kr</span>699
            </span>
            /month
          </div>
          <div class="plan-items">
            <div class="plan-item montserrat-bold">30 Hours per month</div>
            <div class="plan-item  montserrat-bold">
              For organisations with 1000+ members
            </div>
          </div>
          <div class="plan-footer">
            {!isAuthenticated ? (
              <button
                class="button is-fullwidth montserrat-bold"
                onClick={() => loginWithPopup()}
              >
                Get Started
              </button>
            ) : (
              <a href="https://buy.stripe.com/00g16X2CZ4Urd8seUY">
                <button class="button is-fullwidth montserrat-bold">
                  Get Started
                </button>
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
