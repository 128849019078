import React from "react";

import standing24 from "../../assets/standing-24.png";
import standing5 from "../../assets/standing-5.png";
export default function AboutUs() {
  return (
    <>
      <section class="hero is-info" style={{ backgroundColor: "#577590" }}>
        <div class="hero-body">
          <div className="hero-div">
            <div style={{ display: "flex", width: "100%", zIndex: 1 }}>
              <img src={standing5} alt="" style={{ maxWidth: "15vw" }} />
            </div>
            <p className="montserrat-large-title-absolute">ABOUT US</p>
            <div style={{ display: "flex", zIndex: 1 }}>
              <img
                src={standing24}
                alt=""
                style={{ maxWidth: "15vw", transform: "scaleX(-1)" }}
              />
            </div>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="container content">
          <h3 class="title is-3">Our Vision</h3>
          <p>
            We envision a world where every church service is accessible to
            everyone, fostering a more connected and unified community. By
            breaking down language barriers, we help churches create an
            environment where all members can engage deeply with the message,
            music, and fellowship.
          </p>

          <h3 class="title is-3">What We Do</h3>
          <p>
            ChurchLingo provides real-time transcription and translation
            services that capture every word spoken during your services. Our
            technology instantly translates and transcribes sermons, prayers,
            and worship into multiple languages, ensuring that no one is left
            out of the experience.
          </p>

          <h3 class="title is-3">Why Choose Us?</h3>
          <ul>
            <li>
              <strong>Real-Time Solutions:</strong> Our service delivers
              immediate transcription and translation, making it easy for your
              congregation to follow along in their preferred language.
            </li>
            <li>
              <strong>Broad Language Options:</strong> Support for a wide range
              of languages helps you reach a diverse audience.
            </li>
            <li>
              <strong>Seamless Integration:</strong> Our technology integrates
              smoothly with your existing audio systems.
            </li>
            <li>
              <strong>Commitment to Accessibility:</strong> We are dedicated to
              enhancing the worship experience for all members of your
              community.
            </li>
          </ul>

          <h3 class="title is-3">Our Story</h3>
          <p>
            Founded with a passion for inclusivity and innovation, Our goal is
            to support churches in their mission to welcome and engage every
            individual, regardless of language or hearing ability.
          </p>

          <h3 class="title is-3">Get in Touch</h3>
          <p>
            We’re here to help your church create a more inclusive worship
            experience. Contact us to learn more about our services, schedule a
            demo, or start your free trial.
          </p>

          <p>
            <strong>Together, let’s make worship accessible to all.</strong>
          </p>
        </div>
      </section>
    </>
  );
}
