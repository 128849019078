import { Auth0Provider } from "@auth0/auth0-react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "bulma/css/bulma.min.css";
import "bulma-pricingtable/dist/css/bulma-pricingtable.min.css";
import "@creativebulma/bulma-divider/dist/bulma-divider.min.css";

import "./main.css";
import BroadcastPage from "./components/BroadcasterPage/BroadcastPage";
import LandingPage from "./components/LandingPage/LandingPage";

import Header from "./components/Header/Header";

import Footer from "./components/Footer/Footer";
import ViewerLandingPage from "./components/ViewerPage/ViewerLandingPage";
import AboutUs from "./components/AboutPage/AboutUs";
import PricingPage from "./components/PricingPage/PricingPage";
import { Helmet } from "react-helmet";
function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      useRefreshTokens
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin + "/broadcast",
        audience: process.env.REACT_APP_AUTH0_AUDIENCE, // Value in Identifier field for the API being called.
        scope: "openid profile email", // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
      }}
    >
      <Helmet>
        <title>Churchlingo - Live Sermon Transcription and Translation</title>
        <meta
          name="description"
          content="Churchlingo allows churches to live transcribe sermons to different languages, making them accessible to a global audience."
        />
        <meta
          property="og:title"
          content="Churchlingo - Live Sermon Transcription and Translation"
        />
        <meta name="viewport" content="width=device-width" />
        <meta
          property="og:description"
          content="Churchlingo allows churches to live transcribe sermons to different languages, making them accessible to a global audience."
        />
        <meta property="og:image" content="http://example.com/logo.jpg" />
        <meta property="og:url" content="http://churchlingo.com" />
      </Helmet>
      <BrowserRouter>
        <Header></Header>
        <div className="App" style={{ minHeight: "100vh" }}>
          <Routes>
            <Route element={<LandingPage />} path="/" exact />
            <Route element={<BroadcastPage />} path="/broadcast" exact />
            <Route element={<AboutUs />} path="/about-us" />
            <Route element={<PricingPage />} path="/pricing" />
            <Route element={<ViewerLandingPage />} path="/viewerlanding/:id" />
          </Routes>
        </div>
      </BrowserRouter>

      <Footer></Footer>
    </Auth0Provider>
  );
}

export default App;
